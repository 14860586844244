import React from 'react';
import Image from 'next/image';
import { Skeleton } from '@mui/material';

export enum TopicMenuType {
  // eslint-disable-next-line no-unused-vars
  thaiwatsadu = 1,
  // eslint-disable-next-line no-unused-vars
  bnbhome = 2,
  // eslint-disable-next-line no-unused-vars
  hybrid = 3,
}

interface Props {
  topic?: string;
  color?: string;
  noImage?: boolean;
  type?: TopicMenuType;
}

export default function TopicMenu({ topic, color, noImage, type }: Props) {
  return topic ? (
    <div className="flex items-center px-1 xl:px-0">
      {!noImage && (
        <Image
          src={
            type === TopicMenuType.bnbhome
              ? '/images/svg/hybrid/bnbhome_topic.svg'
              : type === TopicMenuType.hybrid
              ? '/images/svg/hybrid/hybrid_topic.svg'
              : '/images/p_thai/thaiwatdadu_topic.svg'
          }
          alt="menu_topic"
          height={78}
          width={78}
          style={{
            width: type === TopicMenuType.hybrid ? 69 : '2rem',
            height: type === TopicMenuType.hybrid ? 78 : '2rem',
          }}
        />
      )}
      <span
        className="font-bold text-xl leading-4 sm:text-2xl sm:leading-4 pt-1 ms-2"
        style={{
          color: color,
        }}
      >
        {topic}
      </span>
      {type === TopicMenuType.bnbhome && (
        <Image
          className="ms-1.5"
          src={'/images/svg/hybrid/bnbhome_logo.svg'}
          alt="menu_topic"
          height={48}
          width={91}
          style={{ height: '1.75rem' }}
        />
      )}
    </div>
  ) : (
    <div className="flex items-center pt-1">
      {noImage ? (
        <></>
      ) : (
        <Skeleton variant="circular" width="2rem" height="2rem" />
      )}
      <div className="text-2xl ms-2">
        <Skeleton variant="text" width="6rem" />
      </div>
    </div>
  );
}
