import { modelValue } from '@/utils/modelValue';

export interface ShopByStyleModel {
  isPrimary: boolean;
  base64?: string;
  pimImage?: string;
  slugname?: string;
  name?: string;
  description?: string;
}
export class ShopByStyleModelConverter {
  public static fromJson(json: any): ShopByStyleModel {
    const model: ShopByStyleModel = {
      isPrimary: json['isprimary'] === true ? true : false,
      base64: json['base64'],
      pimImage: json['pimImage'],
      slugname: json['slugname'],
      name: json['catename'],
      description: json['description'],
    };
    return modelValue(model);
  }
}

export interface CMSHomePageModel {
  data?: Banner[];
  flashsale?: ProductSlide;
  shopbyroom?: Shopbyroom;
  brand?: Brand[];
  brandBanner?: any[];
  loopbyroom?: string;
  articles?: Article[];
  beyondproject?: Beyondproject[];
  awPopups?: AwPopup;
  awPopupList?: AwPopup[];
  hotpromotions?: ProductSlide[];
  events?: any[];
  promotionbanners?: Banner[][];
  promotionevents?: any[];
  dealofthedays?: ProductSlide[];
  categories?: any[];
  newvfiximage?: string;
  promotionbannersslide?: Banner[];
  floatingEvent?: floatingEvent[];
}

export class CMSHomePageModelConverter {
  public static fromJson(json: any): CMSHomePageModel {
    const model: CMSHomePageModel = {
      data: json['data']
        ? json['data'].map((x: any) => BannerConverter.fromJson(x))
        : undefined,
      flashsale: json['flashsale']
        ? ProductSlideConverter.fromJson(json['flashsale'])
        : undefined,
      shopbyroom: json['shopbyroom']
        ? ShopbyroomConverter.fromJson(json['shopbyroom'])
        : undefined,
      brand: json['brand']
        ? json['brand'].map((x: any) => BrandConverter.fromJson(x))
        : undefined,
      brandBanner: json['brand_banner'] ? json['brand_banner'] : undefined,
      loopbyroom: json['loopbyroom'] ? json['loopbyroom'] : undefined,
      articles: json['articles']
        ? json['articles'].map((x: any) => ArticleConverter.fromJson(x))
        : undefined,
      beyondproject: json['beyondproject'] ? json['beyondproject'] : undefined,
      awPopups:
        json['aw_popups'] && AwPopupConverter.fromJson(json['aw_popups']),
      awPopupList: json['aw_popup_list']
        ? json['aw_popup_list'].map((x: any) => AwPopupConverter.fromJson(x))
        : undefined,
      hotpromotions: json['hotpromotions']
        ? json['hotpromotions'].map((x: any) =>
            ProductSlideConverter.fromJson(x),
          )
        : undefined,
      events: json['events'] ? json['events'] : undefined,
      promotionbanners: json['promotionbanners']
        ? json['promotionbanners'].map((x: any) =>
            x.map((y: any) => BannerConverter.fromJson(y)),
          )
        : undefined,
      promotionevents: json['promotionevents']
        ? json['promotionevents']
        : undefined,
      dealofthedays: json['dealofthedays']
        ? json['dealofthedays'].map((x: any) =>
            ProductSlideConverter.fromJson(x),
          )
        : undefined,
      categories: json['categories'] ? json['categories'] : undefined,
      newvfiximage: json['newvfiximage'] ? json['newvfiximage'] : undefined,
      promotionbannersslide: json['promotionbannersslide']
        ? json['promotionbannersslide'].map((x: any) =>
            BannerConverter.fromJson(x),
          )
        : undefined,
      floatingEvent: json['floating_event']
        ? json['floating_event'].map((x: any) =>
            floatingEventConverter.fromJson(x),
          )
        : undefined,
    };
    return modelValue(model);
  }
}

export interface Article {
  tagSlug?: string;
  name?: string;
  img?: string;
  slug?: string;
  subject?: string;
  title?: string;
}

export class ArticleConverter {
  public static fromJson(json: any): Article {
    const model: Article = {
      tagSlug: json['tag_slug'],
      name: json['name'],
      img: json['img'],
      slug: json['slug'],
      subject: json['subject'],
      title: json['title'],
    };
    return modelValue(model);
  }
}

export interface AwPopup {
  content?: string;
  version?: number;
  platform?: number;
}

export class AwPopupConverter {
  public static fromJson(json: any): AwPopup {
    const model: AwPopup = {
      content: json['content'],
      version: json['version'],
      platform: json['platform'],
    };
    return modelValue(model);
  }
}

export interface Beyondproject {
  url?: string;
  subject?: string;
  title?: string;
  time?: string;
  length?: string;
}

export class BeyondprojectConverter {
  public static fromJson(json: any): Beyondproject {
    const model: Beyondproject = {
      url: json['url'],
      subject: json['subject'],
      title: json['title'],
      time: json['time'],
      length: json['length'],
    };
    return modelValue(model);
  }
}

export interface Brand {
  name: string;
  img: string;
  brand: string;
}

export class BrandConverter {
  public static fromJson(json: any): Brand {
    const model: Brand = {
      name: json['name'],
      img: json['img'],
      brand: json['brand'],
    };
    return modelValue(model);
  }
}

export interface Banner {
  topic: string;
  img: string;
  imgMobile?: string;
  url?: string;
  ahref?: boolean;
  col?: number;
  sequence?: number;
  types?: null;
  showmax?: number;
}

export class BannerConverter {
  public static fromJson(json: any): Banner {
    const model: Banner = {
      topic: json['topic'],
      img: json['img'] ? json['img'] : json['imageSec'],
      imgMobile: json['img_mobile'],
      url: json['url'],
      ahref: json['ahref'],
      col: json['col'],
      sequence: json['sequence'],
      types: json['types'],
      showmax: json['showmax'],
    };
    return modelValue(model);
  }
}

export interface ProductSlide {
  img?: string;
  type?: string;
  showmax?: number;
  color?: string;
  name?: string;
  fontcolor?: string;
  slidesPerRow?: number;
}

export class ProductSlideConverter {
  public static fromJson(json: any): ProductSlide {
    const model: ProductSlide = {
      img: json['img'],
      type: json['type'],
      showmax: Number(json['showmax'] ?? json['showmax'] ?? '0'),
      color: json['color'],
      name: json['name'],
      fontcolor: json['fontcolor'],
    };
    return modelValue(model);
  }
}

export interface Shopbyroom {
  img?: string;
  subject?: string;
  description?: string;
  goto?: string;
  position?: Position[];
}

export class ShopbyroomConverter {
  public static fromJson(json: any): Shopbyroom {
    const model: Shopbyroom = {
      img: json['img'],
      subject: json['subject'],
      description: json['description'],
      goto: json['goto'],
      position: json['position']
        ? json['position'].map((x: any) => PositionConverter.fromJson(x))
        : undefined,
    };
    return modelValue(model);
  }
}

export interface Position {
  x: number;
  y: number;
  sku: string;
  roomData?: SkuRoomData;
}

export class PositionConverter {
  public static fromJson(json: any): Position {
    const model: Position = {
      x: Number(json['x']),
      y: Number(json['y']),
      sku: json['sku']?.toString() ?? '',
    };
    return modelValue(model);
  }
}

export interface SkuRoomData {
  sku: string;
  slugname?: string;
  base64?: string;
  pimImage?: string;
  name?: string;
  price?: string;
}

export class SkuRoomDataConverter {
  public static fromJson(json: any): SkuRoomData {
    const model: SkuRoomData = {
      sku: json['sku']?.toString() ?? '',
      slugname: json['slugname'].toString(),
      base64: json['base64'],
      pimImage: json['pimImage'],
      name: json['name'].toString(),
      price: json['price'].toString(),
    };
    return modelValue(model);
  }
}

export interface floatingEvent {
  id?: number;
  slug?: string;
  imageCircle?: string;
  imageBackground?: string;
  label?: floatingEventLabel;
  status?: boolean;
}

export class floatingEventConverter {
  public static fromJson(json: any): floatingEvent {
    const model: floatingEvent = {
      id: json['id'],
      slug: json['slug'].toString(),
      imageCircle: json['image_circle'],
      imageBackground: json['image_background'],
      label: json['label']
        ? floatingEventLabelConverter.fromJson(json['label'])
        : undefined,
      status: json['status'] === true ? true : false,
    };
    return modelValue(model);
  }
}
export interface floatingEventLabel {
  firstTitle?: string;
  firstFontColor?: string;
  firstFontStoke?: string;
  secondTitle?: string;
  secondFontColor?: string;
  secondFontStoke?: string;
  buttonDisplayName?: string;
  buttonColor?: string;
}
export class floatingEventLabelConverter {
  public static fromJson(json: any): floatingEventLabel {
    const model: floatingEventLabel = {
      firstTitle: json['first_title'],
      firstFontColor: json['first_font_color'],
      firstFontStoke: json['first_font_stoke'],
      secondTitle: json['second_title'],
      secondFontColor: json['second_font_color'],
      secondFontStoke: json['second_font_stoke'],
      buttonDisplayName: json['button_display_name'],
      buttonColor: json['button_color'],
    };
    return modelValue(model);
  }
}
