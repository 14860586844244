import { modelValue } from '@/utils/modelValue';
import {
  Article,
  ArticleConverter,
  Banner,
  BannerConverter,
  ProductSlide,
  ProductSlideConverter,
} from './HomePage.model';
import { ProductModel, ProductModelConverter } from './Product.model';
import {
  BundleModel,
  BundleModelConverter,
  ProductBundleConverter,
} from './Bundle.model';
import { RewardConverter } from './profile/MyVoucherDetail.model';
// import { ProductModel, ProductModelConverter } from './Product.model';

export interface CMSCategoryPageModel {
  subject: string;
  url: string;
  contents: Content[];
  section1?: string;
}
export class CMSCategoryPageModelConverter {
  public static fromJson(json: any): CMSCategoryPageModel {
    const model: CMSCategoryPageModel = {
      subject: json['subject'],
      url: json['url'],
      contents: json['contents']
        ? json['contents'].map((e: any) => ContentConverter.fromJson(e))
        : undefined,
      section1: json['section1'],
    };
    return modelValue(model);
  }
}
export interface Content {
  detail: DetailElement[] | null;
  detailString?: string | null;
  detailBanner?: Banner[];
  detailArticle?: Article[];
  skus: any[];
  color: null;
  colorSecond: null;
  keyword: null;
  isSlide: boolean;
  type: CmsType;
  title: null | string;
  detailFlashSale?: ProductSlide;
  detailBundleImage?: BundleModel[];
  detailBundle?: BundleModel[];
  detailCoupon?: [];
  detailDefault?: string | DefaultSkuPrice;
  productskus?: ProductModel[] | [];
  bundleskus?: ProductModel[] | [];
  keyproduct?: [];
  skuProductskus?: { [key: string]: ProductModel[] };
}
export enum CmsType {
  // eslint-disable-next-line no-unused-vars
  coupon = 'coupon',
  // eslint-disable-next-line no-unused-vars
  bundle = 'bundle',
  // eslint-disable-next-line no-unused-vars
  bundleimage = 'bundleimage',
  // eslint-disable-next-line no-unused-vars
  flashsale = 'flashsale',
  // eslint-disable-next-line no-unused-vars
  sku = 'sku',
  // eslint-disable-next-line no-unused-vars
  heroBanner = 'heroBanner',
  // eslint-disable-next-line no-unused-vars
  isCategoryPage = 'isCategoryPage',
  // eslint-disable-next-line no-unused-vars
  textDesc = 'textDesc',
  // eslint-disable-next-line no-unused-vars
  seoLandingPage = 'seoLandingPage',
  // eslint-disable-next-line no-unused-vars
  articleCat = 'article_cat',
  // eslint-disable-next-line no-unused-vars
  default = 'default',
  // eslint-disable-next-line no-unused-vars
  seo = 'seo',
  // eslint-disable-next-line no-unused-vars
  issueCoupon = 'issueCoupon',
  // eslint-disable-next-line no-unused-vars
  article = 'article',
}
export class ContentConverter {
  public static fromJson(json: any): Content {
    const model: Content = {
      detail:
        json['type'] === CmsType.sku &&
        json['detail'] &&
        Array.isArray(json['detail']) &&
        json['detail'].length > 0
          ? json['detail'].map((e: any) => DetailElementConverter.fromJson(e))
          : null,
      detailString: [
        CmsType.seoLandingPage,
        CmsType.textDesc,
        CmsType.article,
      ].includes(
        json['type'] ||
          (json['type'] === CmsType.sku &&
            json['detail'] &&
            typeof json['detail'] === 'string'),
      )
        ? json['detail']
        : undefined,
      detailBanner:
        json['type'] === CmsType.heroBanner &&
        json['detail'] &&
        json['detail'].length > 0
          ? json['detail'].map((e: any) => BannerConverter.fromJson(e))
          : undefined,
      detailArticle:
        json['type'] === CmsType.articleCat &&
        json['detail'] &&
        json['detail'].length > 0
          ? json['detail'].map((e: any) => ArticleConverter.fromJson(e))
          : undefined,
      skus: json['sku'] ? json['sku'] : json['skus'] ? json['skus'] : [],
      color: json['color'],
      colorSecond: json['colorSecond'],
      keyword: json['keyword'],
      isSlide: json['is_slide'] && json['is_slide'] === true ? true : false,
      type: json['type'],
      title: json['title'],
      detailFlashSale:
        json['type'] === CmsType.flashsale
          ? json['detail']
            ? ProductSlideConverter.fromJson(json['detail'])
            : undefined
          : undefined,
      detailBundleImage:
        json['type'] === CmsType.bundleimage &&
        json['detail'] &&
        json['detail'].length > 0
          ? json['detail'].map((e: any) => BundleModelConverter.fromJson(e))
          : undefined,
      detailCoupon:
        json['type'] === CmsType.coupon &&
        json['rewardlist'] &&
        json['rewardlist'].length > 0
          ? json['rewardlist'].map((e: any) => RewardConverter.fromJson(e))
          : undefined,
      detailDefault:
        json['type'] === CmsType.default ? json['detail'] : undefined,
      productskus:
        [CmsType.flashsale, CmsType.default].includes(json['type']) &&
        json['productskus'] &&
        json['productskus'].length > 0
          ? json['productskus'].map((x: any) =>
              ProductModelConverter.fromJson(x),
            )
          : [],
      keyproduct: json['keyproduct'],
      skuProductskus:
        json['type'] === CmsType.sku && json['productskus']
          ? Object.keys(json['productskus']).reduce(
              (acc: { [key: string]: ProductModel[] }, key: string) => {
                acc[key] = json['productskus'][key].map((e: any) =>
                  ProductModelConverter.fromJson(e),
                );
                return acc;
              },
              {},
            )
          : undefined,
      detailBundle:
        json['type'] === CmsType.bundle &&
        json['productskus'] &&
        json['productskus'].length > 0
          ? json['productskus'].map((elements: any) =>
              elements && elements.length > 0
                ? elements.map((x: any) => ProductBundleConverter.fromJson(x))
                : undefined,
            )
          : undefined,
    };
    return modelValue(model);
  }
}
export interface DetailElement {
  sku?: string;
  keyword?: string;
}
export class DetailElementConverter {
  public static fromJson(json: any): DetailElement {
    const model: DetailElement = {
      sku: json['sku'],
      keyword: json['keyword'],
    };
    return modelValue(model);
  }
}
export interface DefaultSkuPrice {
  sku?: string;
  price?: string;
}
