import { BundleModel } from '@/models/Bundle.model';
import React, { useEffect, useState } from 'react';
import { Button, Grid } from '@mui/material';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import BundleCard from './BundleCard';

interface Props {
  data: BundleModel;
  isFromCms?: boolean;
}
export default function BundleSection({ data, isFromCms }: Props) {
  const { t } = useTranslation('');
  const router = useRouter();
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    setIsShow(false);
  }, [router.asPath]);

  const toggleBundle = () => {
    setIsShow((prevIsShow) => !prevIsShow);
  };

  return (
    <>
      {data && data.products && data.products.length > 0 ? (
        <>
          {!isFromCms && (
            <h2 className="text-2xl font-bold text-center text-primary my-2">
              {t('product:buythebestsetataspec')}
            </h2>
          )}

          {data.products.map((product, i) => (
            <React.Fragment key={i}>
              {i === 0 || isShow ? (
                <BundleCard product={product} isFromCms={isFromCms} />
              ) : (
                <></>
              )}
            </React.Fragment>
          ))}
          {data && data.products && data.products.length > 1 && (
            <Grid
              container
              sx={{ marginBottom: '20px' }}
              alignContent="center"
              justifyContent="center"
            >
              <Grid item xs={11}>
                <Button
                  className="text-center bg-primary btnfilter rounded-lg"
                  variant="outlined"
                  sx={{
                    color: 'white',
                    borderColor: '#B8292F',
                    background: '#B8292F',
                    width: '100%',
                    '&:hover': {
                      background: '#B8292F',
                    },
                  }}
                  onClick={toggleBundle}
                >
                  <i
                    className={`bi bi-chevron-${
                      isShow ? 'up' : 'down'
                    } text-xs h-3 leading-3 pr-2`}
                  ></i>
                  {isShow ? t('close') : t('product:seeothergreatdeals')}
                  <i
                    className={`bi bi-chevron-${
                      isShow ? 'up' : 'down'
                    } text-xs h-3 leading-3 pl-2`}
                  ></i>
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
}
