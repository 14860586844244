import { Button, Grid } from '@mui/material';
import React, { useState } from 'react';
import styles from '../../styles/bundle.module.css';
import { useTranslation } from 'next-i18next';
import AddToCartButton from '../ProductCard/AddToCartButton';
import { SkuWithQty } from '@/models/AddToCart.model';
import Spinner from '@/components/common/Spinner';
import Image from 'next/image';

interface Props {
  allpercent: string;
  allplppprice: string;
  product: string;
  skubundle: string;
  skuQtyList?: SkuWithQty[];
  setId?: string;
  allprice: string;
  allpricesave: string;
  isOutOfStock: boolean;
  isBundleSoft?: boolean;
  sumOrgPrice?: string;
  sumSpecialPrice?: string;
  sumReduce?: string;
  sumTotalLeft?: string;
  isDisbledCart?: boolean;
  isAddingBundleSoft?: boolean;
  callBackAddCartBundleSoft: () => void;
}
export default function BundleChildMobile({
  allpercent,
  allplppprice,
  product,
  skubundle,
  setId,
  skuQtyList,
  allprice,
  allpricesave,
  isOutOfStock,
  isBundleSoft,
  sumOrgPrice,
  sumSpecialPrice,
  sumReduce,
  sumTotalLeft,
  isDisbledCart,
  isAddingBundleSoft,
  callBackAddCartBundleSoft,
}: Props) {
  const [isChildShow, setChildIsShow] = useState(false);
  const toggleChildBundle = () => {
    setChildIsShow((prevIsShow) => !prevIsShow);
  };
  const { t } = useTranslation('product');

  return isBundleSoft === true ? (
    <Grid
      item
      xs={12}
      sx={{
        display: {
          xs: 'block',
          lg: 'none',
          borderTop: '1px solid rgba(0, 0, 0, .1)',
          borderBottom: '1px solid rgba(0, 0, 0, .1)',
          padding: '10px',
        },
      }}
    >
      <div className="leading-5 p-1">
        <div className="flex justify-between text-md text-grayDark">
          <span>{t('allprice')}</span>
          <span className="font-bold"> ฿ {sumOrgPrice}</span>
        </div>

        <div className="flex justify-between text-md text-primary">
          <span>{t('product:specialpriceincluded')}</span>
          <span className="font-bold"> ฿ {sumSpecialPrice}</span>
        </div>
        <div className="flex justify-between px-2 text-black bg-secondaryYellow">
          <div className="flex">
            <Image
              src="/images/svg/pdp/money.svg"
              alt="baht"
              width={24}
              height={24}
            />
            <span className="ml-2">{t('product:totalsavings')}</span>
          </div>
          <div className="font-bold"> ฿ {sumReduce}</div>
        </div>
        <div className="flex justify-between text-md text-primary">
          <span>{t('product:onlyleft')}</span>
          <span className="font-bold">
            ฿<span className="font-price"> {sumTotalLeft}</span>
          </span>
        </div>
      </div>
      <Button
        onClick={() => callBackAddCartBundleSoft()}
        disabled={isDisbledCart}
        className={`text-white text-sm bg-primary hover:bg-secondaryPurple h-[1.75rem] leading-3 w-full`}
        sx={{
          backgroundColor: '#B8292F',
          color: 'white',
          '&:hover': {
            backgroundColor: '#B8292FD0',
          },
          '&:disabled': {
            backgroundColor: '#EBEBEB',
          },
          height: `1.75rem`,
          fontSize: '0.75rem',
          lineHeight: '0.75rem',
          minWidth: '0',
          width: '100%',
        }}
        aria-label="add to cart"
      >
        {isAddingBundleSoft === true ? (
          <Spinner size={18} borderSize={2} />
        ) : (
          <div className="flex">
            <i className="bi bi-cart text-xs h-3"></i>
          </div>
        )}
        <div className={`overflow-hidden ms-1 sm:lg-1 w-full line-clamp-2`}>
          {t('productcard:addtocart')}
        </div>
      </Button>
    </Grid>
  ) : (
    <Grid
      item
      xs={12}
      sx={{
        display: {
          xs: 'block',
          lg: 'none',
          borderTop: '1px solid rgba(0, 0, 0, .1)',
          borderBottom: '1px solid rgba(0, 0, 0, .1)',
          padding: '10px',
        },
      }}
    >
      <div
        className="flex justify-between"
        onClick={() => {
          toggleChildBundle();
        }}
      >
        <div className={`${styles.disccirclebundle} my-auto`}>
          {allpercent}
          <span style={{ fontSize: '10px' }}>%</span>
          <br />
          <span style={{ fontSize: '15px' }}>OFF</span>
        </div>
        <div className="mx-auto text-center px-2">
          <span className="font-semibold">{t('thewholesetischeaper')} </span>
          {/* <br /> */}
          <span className={`text-redPrice break-words`}>{t('onlyleft')} </span>
          <span className="text-redPrice">
            <span
              className={`text-lg font-bold font-price`}
              style={{ lineHeight: '1em' }}
            >
              {allplppprice}
            </span>
          </span>
        </div>
        <div className="my-auto">
          <i
            className={`bi bi-chevron-${
              isChildShow ? 'up' : 'down'
            } text-xs h-3 leading-3 pr-2`}
          ></i>
        </div>
      </div>
      {isChildShow ? (
        <>
          {product && JSON.parse(product).length > 0 ? (
            <>
              {JSON.parse(product).map((product: any, i: number) => {
                return (
                  <Grid
                    container
                    alignItems="center"
                    key={i}
                    sx={{
                      borderBottom: '1px solid rgba(0, 0, 0, .1)',
                      marginTop: 1,
                    }}
                  >
                    <Grid item xs={1}>
                      <p className="text-xs">
                        <i className="bi bi-check2-square"></i>
                      </p>
                    </Grid>
                    <Grid item xs={11} className="leading-none">
                      <p className="text-xs">{product.name}</p>
                      <span className="font-semibold text-xs">
                        {product.price}
                      </span>
                    </Grid>
                  </Grid>
                );
              })}
            </>
          ) : (
            <></>
          )}
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            sx={{
              marginTop: 1,
              paddingBottom: 1,
            }}
          >
            <Grid item xs={12} className="text-center leading-5">
              <span>
                {t('refund_price')} {allprice}
              </span>
            </Grid>
            <Grid
              item
              xs={12}
              className="text-center font-bold leading-5"
              sx={{
                borderBottom: '1px solid rgba(0, 0, 0, .1)',
                marginTop: 0,
                paddingBottom: 1,
              }}
            >
              <span>
                {t('thewholesetischeaper')} {allpricesave}
              </span>
            </Grid>
            <Grid item xs={12} className="text-center text-primary">
              <span>
                {t('onlyleft')}{' '}
                <span className="text-redPrice font-bold text-lg font-price">
                  {allplppprice}
                </span>
              </span>
            </Grid>
          </Grid>
          <div className="flex justify-center text-center">
            <div className="w-auto">
              <AddToCartButton
                sku={skubundle}
                setId={setId}
                skuQtyList={skuQtyList}
                isOutOfStock={isOutOfStock}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </Grid>
  );
}
