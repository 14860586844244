import { ProductBundle } from '@/models/Bundle.model';
import { ProductModel, ProductModelConverter } from '@/models/Product.model';
import React, { useState, useEffect } from 'react';
import SlickProductCard from '../../common/SlickProductCard';
import { Button, Grid } from '@mui/material';
import styles from '../../../styles/bundle.module.css';
import slickStyles from '../../../styles/slickProductCard.module.css';
import BundleChildMobile from '../BundleChildMobile';
import AddToCartButton from '../../ProductCard/AddToCartButton';
import { SkuWithQty } from '@/models/AddToCart.model';
import { numberWithCommas } from '@/utils/format';
import Spinner from '@/components/common/Spinner';
import { useTranslation } from 'next-i18next';
import { useSelector } from 'react-redux';
import {
  addToCartMultiItems,
  addingMultiToCartSelector,
  addingMultiToCartSuccessSelector,
  clearAddToCartSuccessSku,
} from '@/store/slices/addToCartSlice';
import { useAppDispatch } from '@/store/store';
import ProductCardBundle from '../ProductCardBundle';
import { showMessageSuccessAutoHide } from '@/utils/showDialog';

interface Props {
  product: ProductBundle;
  isFromCms?: boolean;
}

export default function BundleCard({ product, isFromCms }: Props) {
  const { t } = useTranslation('product');
  const dispatch = useAppDispatch();
  const addingMultiToCart = useSelector(addingMultiToCartSelector);
  const addingMultiToCartSuccess = useSelector(
    addingMultiToCartSuccessSelector,
  );
  const [selectedProduct, setSelectedProduct] = useState<
    { sku: string; qty: number }[]
  >([]);
  const [isDisbledCart, setIsDisbledCart] = useState(false);

  useEffect(() => {
    if (addingMultiToCartSuccess) {
      showMessageSuccessAutoHide({
        text: `${t('common:productaddedtocart')}`,
      }).then(() => {
        dispatch(clearAddToCartSuccessSku());
      });
    }
  }, [addingMultiToCartSuccess, t, dispatch]);

  const handleProduct = (selectProduct: { sku: string; qty: number }[]) => {
    setSelectedProduct(selectProduct);
    if (selectProduct && selectProduct.length > 1) {
      setIsDisbledCart(false);
    } else {
      setIsDisbledCart(true);
    }
  };

  const handleAddtocartSoftBundle = (
    skuQtyList: {
      sku: string;
      qty: number;
    }[],
  ) => {
    dispatch(
      addToCartMultiItems({
        request: (skuQtyList ?? []).map((e) => ({
          sku: e.sku,
          qty: e.qty,
        })),
      }),
    );
  };

  const skuQtyList: SkuWithQty[] = [];
  let allorgprice: string = '';
  let allpercent: string = '';
  let allprice: string = '';
  let allplppprice: string = '';
  let proEnddate: string = '';
  let setid: string = '';
  let skubundle: string = '';
  let allpricesave: string = '';
  let convertedProducts: ProductModel[] = [];
  let isbundlesoft: boolean = false;
  let flgShowOrgPrice: number = 0;
  let sumOrgPrice: number = 0;
  let sumSpecialPrice: number = 0;
  let sumReduce: number = 0;
  let sumTotalLeft: number = 0;
  if (Array.isArray(product)) {
    product.forEach((element: ProductBundle) => {
      skuQtyList.push({
        sku: element.skc?.toString() ?? '',
        qty: element.qntBundle ?? 1,
      });
    });
    allorgprice = product[0].allorgprice;
    allpercent = product[0].allpercent;
    allprice = product[0].allprice;
    allplppprice = product[0].allplppprice;
    proEnddate = product[0].proEnddate;
    setid = product[0].setid;
    skubundle = isFromCms ? '' : product[0].skc;
    allpricesave = product[0].allpricesave;
    isbundlesoft = product[0].isBundleSoft;
    flgShowOrgPrice = product[0].flagShowORGPRICE;

    const selectedProductMapProduct: ProductBundle[] = product.filter((x) =>
      selectedProduct.find((e) => e.sku === x.skc?.toString()),
    );

    const selectedProductMap: ProductBundle[] = selectedProductMapProduct.map(
      (x) => {
        let checkSelected = selectedProduct.find(
          (e) => e.sku === x.skc?.toString(),
        );
        if (checkSelected) {
          x.selectedQty = checkSelected.qty;
        }
        return x;
      },
    );

    (sumOrgPrice = selectedProductMap.reduce(
      (a, b) => a + Number(b?.selectedQty ?? 0) * Number(b?.orgPrice ?? 0),
      0,
    )),
      (sumSpecialPrice = selectedProductMap.reduce(
        (a, b) =>
          a +
          Number(b?.selectedQty ?? 0) *
            Number(b?.price?.replace(/,/g, '') ?? 0),
        0,
      )),
      (sumReduce = selectedProductMap.reduce(
        (a, b) => a + Number(b?.selectedQty ?? 0) * Number(b?.reduce ?? 0),
        0,
      )),
      (sumTotalLeft = sumSpecialPrice - sumReduce);
    convertedProducts = product?.map((product) =>
      ProductModelConverter.fromJson(product),
    );
  } else {
    console.log('product is not an array');
  }

  return (
    <div className={`bg-white rounded ${isFromCms === true ? 'mt-2' : ''}`}>
      <Grid container sx={{ marginBottom: '20px' }}>
        <Grid item lg={10} xs={12}>
          <div
            className={`${
              isbundlesoft ? 'flex flex-col lg:flex-row p-2' : ''
            } bg-white`}
          >
            {isbundlesoft && (
              <div className="relative my-auto w-full lg:w-1/4">
                <ProductCardBundle
                  product={convertedProducts[0]}
                  isMain={true}
                  isBundleSoft={true}
                />
                <span
                  className={`${slickStyles.plusBundle} absolute`}
                  style={{ right: '-0.45em' }}
                >
                  +
                </span>
              </div>
            )}
            <div
              className={`px-2 py-2 bundleList ${
                isbundlesoft
                  ? 'w-full lg:w-3/4 bg-background border border-grayLight2 rounded lg:ml-4'
                  : ''
              }`}
            >
              <SlickProductCard
                arrows
                products={convertedProducts}
                listName={`bundle_card`}
                isBundle={true}
                isBundleSoft={isbundlesoft}
                dots={false}
                slidesToShow={isbundlesoft === true ? 3 : 4}
                md={{ slidesToShow: 2 }}
                sm={{ slidesToShow: 2 }}
                xs={{ slidesToShow: 2 }}
                callBackProduct={handleProduct}
              />
            </div>
          </div>
        </Grid>
        <Grid
          sx={{
            display: { xs: 'none', lg: 'block' },
          }}
          item
          lg={2}
          className="text-center px-1"
        >
          <div className="leading-5 p-1">
            <div className="text-md text-grayDark">
              {t('allprice')}{' '}
              {isbundlesoft ? numberWithCommas(sumOrgPrice) : allorgprice}
            </div>
            {flgShowOrgPrice === 1 && (
              <div className="text-md text-primary font-semibold">
                {t('product:specialpriceincluded')}{' '}
                {isbundlesoft ? numberWithCommas(sumSpecialPrice) : allprice}
              </div>
            )}
          </div>

          <div className={`${styles.discimage}`}>
            {isbundlesoft ? (
              <div className={`${styles.discribbonbundlesoft} mx-auto`}>
                <div className={`${styles.morecheapsoft}`}>
                  <span>{t('product:buytogethersavemore')}</span>
                </div>
                <div className={`${styles.discamount} mx-auto text-redPrice`}>
                  ฿ <span className="text-2xl font-price">{sumReduce}</span>
                </div>
              </div>
            ) : (
              <div className={`${styles.discribbonbundle} mx-auto`}>
                <div className={`${styles.morecheap}`}>
                  <span>{t('product:thewholesetischeaper')}</span>
                </div>
                <div
                  className={`${styles.discpercent} mx-auto text-redPrice font-price`}
                >
                  {allpercent}
                  <span className="text-2xl">%</span>
                </div>
              </div>
            )}

            <div className={`${styles.lessStock} mx-auto`}>
              <span
                className={`${styles.lessStockText} text-md text-redPrice my-2`}
              >
                {t('product:onlyleft')}
              </span>
              <span className="text-redPrice">
                ฿{' '}
                <span
                  className={`text-2xl font-price`}
                  style={{ lineHeight: '1em' }}
                >
                  {isbundlesoft ? numberWithCommas(sumTotalLeft) : allplppprice}
                </span>
              </span>
            </div>
          </div>
          <div className="text-xs">
            <span className="text-grayDark">
              {t('product:thispriceisvalidunti')} {proEnddate} {t('only')}
            </span>
          </div>
          {isbundlesoft ? (
            <Button
              onClick={() => handleAddtocartSoftBundle(selectedProduct)}
              disabled={isDisbledCart}
              className={`text-white text-sm bg-primary hover:bg-secondaryPurple h-[1.75rem] leading-3 w-auto min-w-0`}
              sx={{
                backgroundColor: '#B8292F',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#B8292FD0',
                },
                '&:disabled': {
                  backgroundColor: '#EBEBEB',
                },
                height: `1.75rem`,
                fontSize: '0.75rem',
                lineHeight: '0.75rem',
                minWidth: '0',
                width: 'auto',
              }}
              aria-label="add to cart"
            >
              {addingMultiToCart === true ? (
                <Spinner size={18} borderSize={2} />
              ) : (
                <div className="flex">
                  <i className="bi bi-cart text-xs h-3"></i>
                </div>
              )}
              <div
                className={`overflow-hidden ms-1 sm:lg-1 w-full line-clamp-2`}
              >
                {t('productcard:addtocart')}
              </div>
            </Button>
          ) : (
            <>
              <AddToCartButton
                sku={skubundle && skubundle !== '' ? skubundle : undefined}
                setId={setid}
                skuQtyList={skuQtyList}
                isOutOfStock={
                  convertedProducts.find(
                    (e) => e.stockAvail && e.stockAvail <= 0,
                  ) !== undefined
                }
              />
            </>
          )}
        </Grid>
        <BundleChildMobile
          allpercent={`${allpercent}`}
          allplppprice={`${allplppprice}`}
          product={JSON.stringify(product)}
          skubundle={`${skubundle}`}
          setId={setid}
          skuQtyList={skuQtyList}
          allprice={`${allprice}`}
          allpricesave={`${allpricesave}`}
          isOutOfStock={
            convertedProducts.find((e) => e.stockAvail && e.stockAvail <= 0) !==
            undefined
          }
          sumOrgPrice={numberWithCommas(sumOrgPrice)}
          sumSpecialPrice={numberWithCommas(sumSpecialPrice)}
          sumReduce={numberWithCommas(sumReduce)}
          sumTotalLeft={numberWithCommas(sumTotalLeft)}
          isBundleSoft={isbundlesoft}
          isDisbledCart={isDisbledCart}
          isAddingBundleSoft={addingMultiToCart}
          callBackAddCartBundleSoft={() =>
            handleAddtocartSoftBundle(selectedProduct)
          }
        />
      </Grid>
    </div>
  );
}
