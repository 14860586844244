import SliderLayout from '@/components/common/SliderLayout';
import TopicMenu from '@/components/widgets/TopicMenu';
import Skeleton from '@mui/material/Skeleton';
import React from 'react';

interface Props {
  noBg?: boolean;
  noImage?: boolean;
  noTopic?: boolean;
  noPadding?: boolean;
}

export default function ProductSlideLoading({
  noBg,
  noImage,
  noTopic,
  noPadding,
}: Props) {
  const desktopWidth = 1000;
  const desktopHeight = 1270;

  return (
    <div className={noBg === true ? undefined : 'pt-2 px-1 mb-1 bg-white'}>
      {!noTopic && (
        <div className="flex items-center justify-between px-0.5 sm:px-1">
          <div className="w-1/3 hidden md:block"></div>
          <div className="w-1/2 md:w-1/3 flex justify-start md:justify-center">
            <TopicMenu noImage={noImage} />
          </div>
          <div className="w-1/2 md:w-1/3"></div>
        </div>
      )}
      <div className={noPadding ? 'pt-0 pb-1 -mx-1' : 'pt-2 pb-1'}>
        <SliderLayout
          dots={false}
          arrows={false}
          autoplay={false}
          slidesToShow={4}
          xs={{ slidesToShow: 2 }}
          sm={{ slidesToShow: 2 }}
          md={{ slidesToShow: 2 }}
          lg={{ slidesToShow: 3 }}
        >
          {[...Array(4)]?.map((e, i) => (
            <div key={`slider-${i}`} className="px-1">
              <div
                className={`relative w-full`}
                style={{
                  paddingTop: `calc(${desktopHeight}/${desktopWidth}*100%)`,
                }}
              >
                <Skeleton
                  variant="rectangular"
                  className="rounded-md absolute top-0 left-0"
                  style={{
                    width: '100%',
                    height: '100%',
                  }}
                />
              </div>
            </div>
          ))}
        </SliderLayout>
      </div>
    </div>
  );
}
